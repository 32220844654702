@font-face {
  font-family: 'Lato';
  font-weight: 900;
  src: local('Lato'),
       url('./lato-black-webfont.woff2') format('woff2'),
       url('./lato-black-webfont.woff') format('woff');
}
@font-face {
  font-family: 'Lato';
  font-weight: 700;
  src: local('Lato'),
       url('./lato-bold-webfont.woff2') format('woff2'),
       url('./lato-bold-webfont.woff') format('woff');
}
@font-face {
  font-family: 'Lato';
  font-weight: 400;
  src: local('Lato'),
       url('./lato-regular-webfont.woff2') format('woff2'),
       url('./lato-regular-webfont.woff') format('woff');
}